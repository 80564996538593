@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  background-color: var(--body-background);
  color: var(--body--color);
}

:root {
  --body-color: #e4e9f7;
  --sidebar-color: #fff;
  --primary-color: #695cfe;
  --primary-color-light: #f6f5ff;
  --toggle-color: #ddd;
  --text-color: #707070;

  --tran-02: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.4s ease;
  --tran-05: all 0.5s ease;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.margin_top10 {
  margin-top: 20px;
}

.errmsg {
  color: red;
}

.dashboardSummaryDiv {
  text-align: center;
  color: white;
}

.dashboardSummaryDiv h4 {
  background-color: #00a3d5;
    padding: 16px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin: 0px;
    font-size: 20px;
}

.dashboardSummaryDiv h2 {
  background-color: #00baf2;
  font-size: 42px;
  text-shadow: 2px 2px 4px #20336b;
  padding: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin: 0px;
}

.dropDownBG{
  background-color: #20336b !important;
}
