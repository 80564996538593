.custom-bg-color {
    background-color: #20336b;
  }

.bg-light {
    background-color: var(--nav-background) !important;
}

.logo_back {
    background-color: #fff;
    box-shadow: 0 1px 15px #0000001f;
    height: auto;
    width: auto;
    padding: 8px;
    border-radius: 0 0 0 0;
    transition: .2s ease-in-out;
    border-radius: 6px;
}

/* Dark Mode Css */
.dark_mode {
    margin-top: -20px;
    margin-right: 12px;
}

.navbar-brand {
    color: rgb(255 255 255);
}

.navbar-brand:hover {
    color: white;
}

.nav-link {
    color: white;
    border-radius: 8px;
}

.nav-link:focus, .nav-link:hover {
    color: white;
    background-color:#0d6efd;
    
}

.nav-link{
    color: white;
    text-decoration: none;
}
 
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: white;
    background-color: #0d6efd;
}
.dropdown-item {
    padding: 0px;
    margin: 0px;
}
.dropdown-item > a {
    padding: 8px 12px;
    text-decoration: none;
    display: block;
    color: white;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: black;
    background-color:#0d6efd;
}

.dropdown-menu {
    background-color: #20336b;
}
.dark_mode_label {
    width: 65px;
    height: 30px;
    position: relative;
    display: block;
    background: #ebebeb;
    border-radius: 200px;
    box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4),
        inset 0px -5px 15px rgba(255, 255, 255, 0.4);
    cursor: pointer;
    transition: 0.3s;
}

.dark_mode_label:after {
    content: "";
    width: 25px;
    height: 25px;
    position: absolute;
    top: 3px;
    left: 3px;
    background: linear-gradient(180deg, #ffcc89, #d8860b);
    border-radius: 180px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
}


.dark_mode_input {
    width: 0;
    height: 0;
    visibility: hidden;
}

.dark_mode_input:checked+.dark_mode_label {
    background: #242424;
}

.dark_mode_input:checked+.dark_mode_label:after {
    left: 62px;
    transform: translateX(-100%);
    background: linear-gradient(180deg, #777, #3a3a3a);
}

.dark_mode_label:active:after {
    width: 30px;
}

.dark_mode_label svg {
    position: absolute;
    width: 20px;
    top: 5px;
    z-index: 100;
}

.dark_mode_label svg.sun {
    left: 5px;
    fill: #fff;
    transition: 0.3s;
}

.dark_mode_label svg.moon {
    left: 40px;
    fill: #7e7e7e;
    transition: 0.3s;
}

.dark_mode_input:checked+.dark_mode_label svg.sun {
    fill: #7e7e7e;
}

.dark_mode_input:checked+.dark_mode_label svg.moon {
    fill: #fff;
}