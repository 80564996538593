.machineDiv {
  background-color: #7ad5b6;
  border-radius: 10px;
  margin: 12px 0px;
}

.machineDivOff {
  background-color: #d13135;
  border-radius: 10px;
  margin: 12px 0px;
  padding: 24px;
  font-size: 30px;
  color: white;
  text-align: center;
}

.time {
  text-align: center;
  padding: 10px;
  margin-left: 0%;
  background-color: #379874;
  margin-top: 6px;
  font-size: 18px;
  color: white;
}

.machineNo {
  padding: 10px;
  font-size: 18px;
}


.machineNo .text-center {
  display: inline-block;
}

.machineNo .text-right {
  display: inline-block;
}

.machineDiv td {
  text-align: center;
  padding: 8px 0px;
}

.machineDiv table {
  width: 100%;
}

.width40 {
  width: 40%;
}

.width20 {
  width: 20%;
}

.machineSummary {
  margin: 14px 0px;
  padding: 18px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  border-radius: 12px;
  cursor: pointer;
}


.allMachineSummary {
  background-color: #00baf2;
  color: black;
}

.allOnMachineSummary {
  background-color: #379874;
  color: white;
}

.allOffMachineSummary {
  background-color: #d13135;
  color: white;
}

.machineSummaryForStoneCount {
  margin: 14px 0;
  background-color: #263369;
  padding: 18px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  border-radius: 12px;
  color: white;
}