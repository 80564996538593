:root {
  --header-bg-color: #20336b;
  --header-border-color: #20336b;
  --header-text-color: rgb(255, 255, 255);
  --row-border-color: rgb(228, 228, 228);
  --row-odd-bg-color: rgb(255, 255, 255);
  --row-even-bg-color: rgb(228, 228, 228);
  --paging-panel-bg-color: #f3f3f3;
  --paging-row-summary-font-size-large: 15px;
  --paging-row-summary-font-size-small: 10px;
  --picker-field-label-font-size: 10px;
}

.ag-theme-quartz {
  --ag-font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}
.ag-root-wrapper {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  border: var(--ag-borders) var(--ag-border-color);
}

.ag-body-horizontal-scroll-viewport {
  scrollbar-width: thin;
}

.ag-body-vertical-scroll-viewport {
  scrollbar-width: thin;
}

.ag-horizontal-right-spacer {
  scrollbar-width: thin;
}

.ag-header-viewport {
  background-color: var(--header-bg-color) !important;
  border: 2px solid var(--header-border-color) !important;
}

.ag-theme-quartz .ag-header-row {
  color: var(--header-text-color) !important;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.ag-header-cell-resize::after {
  background: white !important;
}

.ag-icon-filter {
  color: white !important;
}

.ag-pinned-right-header {
  border-left: 2px solid white !important;
  background-color: var(--header-bg-color) !important;
}

.ag-pinned-left-header {
  border-right: 2px solid white !important;
  background-color: var(--header-bg-color) !important;
}
.ag-header,
.ag-advanced-filter-header {
  border-bottom: 3px solid #c9c9c9 !important;
}

/* .ag-row-odd {
    background-color: var(--row-odd-bg-color) !important;
  }
  
  .ag-row-even {
    background-color: var(--row-even-bg-color) !important;
  } */

.ag-row {
  border-bottom: 3px solid var(--row-border-color) !important;
}

.ag-paging-panel {
  background-color: var(--paging-panel-bg-color) !important;
}

.ag-paging-row-summary-panel {
  font-size: var(--paging-row-summary-font-size-large);
}

/* Media queries for different screen sizes */

/* For laptops and desktops */
@media (min-width: 1024px) {
  .ag-paging-row-summary-panel {
    font-size: var(
      --paging-row-summary-font-size-large
    ); /* Adjust font size for larger screens */
  }
}

/* For tablets */
@media (max-width: 1023px) and (min-width: 768px) {
  .ag-paging-row-summary-panel {
    font-size: var(
      --paging-row-summary-font-size-large
    ); /* Adjust font size for tablets */
  }
}

/* For mobile devices */
@media (max-width: 767px) {
  .ag-paging-row-summary-panel {
    font-size: var(
      --paging-row-summary-font-size-small
    ); /* Adjust font size for mobile */
  }
  .ag-picker-field .ag-label {
    font-size: var(--picker-field-label-font-size);
  }
}

@media (max-width: 470px) {
  .ag-paging-row-summary-panel {
    font-size: var(--paging-row-summary-font-size-small);
    display: none; /* Adjust font size for mobile */
  }
  .ag-picker-field .ag-label {
    font-size: var(--picker-field-label-font-size);
    display: none;
  }
}

.ag-header-container .ag-icon::before {
  color: white !important;
}

.ag-icon::after {
  color: rgb(0, 0, 0) !important;
}

.ag-root .ag-cell {
  user-select: text !important;
}

.my-grid-margin {
  margin: 5px -1rem -1rem -1rem;
}


.ag-floating-bottom {
  /* border-top: var(--ag-borders-critical) var(--ag-border-color); */
  border-top: 2px solid #20336b !important;
  border-bottom: 2px solid #20336b !important;
  font-weight: 600;
}