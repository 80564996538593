.blur {
  filter: blur(5px);
  /* Adjust the blur strength as needed */
  pointer-events: none;
  /* Allows clicks to pass through the overlay to elements below */
}


.currentCard {
  border: 2px solid;
  border-color: #00baf2 !important;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.arrange {
  display: flex;
  justify-content: space-between;
  margin: 16px 0px;
}

.arrange span {
  font-weight: 600;
  font-size: 18px;
}

.currentTitle {
  background: #00baf2;
  padding: 10px;
  border-radius: 8px;
  font-weight: 600;
  color: #20336b;
}

.buildingCLR {
  color: #00baf2;
}

.historyTitle {
  background: #20336b;
  padding: 10px;
  border-radius: 8px;
  font-weight: 600;
  color: aliceblue;
}

.historyCard {
  border: 2px solid;
  border-color: #263369 !important;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


.border-primary-history {
  border-color: #263369 !important;
}

.historyCLR {
  color: #263369;
}

.userF-Size {
  font-size: 18px;
}

.currentDate {
  background: silver;
  text-align: center;
  font-size: 18px;
  border-radius: 8px;
  color: black;
  display: inline-block;
  padding: 6px 14px;
  margin: 5px 0px 5px 15px;
}

.historyDate {
  background: silver;
  text-align: center;
  font-size: 18px;
  border-radius: 8px;
  color: black;
  display: inline-block;
  padding: 6px 14px;
  margin: 5px 0px 5px 15px;
}

.imgDate {
  background: #00baf2;
  padding: 10px;
  border-radius: 8px;
  font-weight: 600;
  color: #20336b;
  height: 40px;
}