/* Modal overlay to cover the entire page */
.PhotoGallery-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal content container */
.PhotoGallery-modal-content {
  background: rgb(1, 0, 0);
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  overflow: auto; /* Enable scrolling if content overflows */
}

/* Button styling */
.PhotoGallery-zoom-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 2px;
}

.PhotoGallery-slide-wrapper {
  height: 70vh; 
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-shadow: 0px 1px 10px 1px #7d7d7d;
}

.PhotoGallery-mySlides {
  height: 100%;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.PhotoGallery-mySlides img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.PhotoGallery-prev,
.PhotoGallery-next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 50px;
  transition: 0.6s ease;
  user-select: none;
  -webkit-user-select: none;
  text-decoration: none;
}

.PhotoGallery-prev {
  left: 0;
}

.PhotoGallery-next {
  right: 0;
}

.PhotoGallery-prev:hover,
.PhotoGallery-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.PhotoGallery-demo:hover {
  opacity: 0.9;
  border: 2px solid white;
}

.PhotoGallery-zoomable {
  transition: transform 0.3s ease;
  cursor: grab;
}

.PhotoGallery-zoomable:active {
  cursor: grabbing;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .PhotoGallery-prev,
  .PhotoGallery-next {
    font-size: 30px; /* Smaller navigation buttons */
  }
}

@media (max-width: 480px) {
  .PhotoGallery-prev,
  .PhotoGallery-next {
    font-size: 20px; /* Even smaller navigation buttons */
  }
}

.PhotoGallery-no-scroll {
  overflow: hidden;
}

.PhotoGallery-scroll-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-top: 1vh;
  height: 15vh;
  width: 100%;
  padding: 15px;
}

.PhotoGallery-scroll-container img {
  /* Ensure images fill the column width */
  /* Cover the entire area without distorting the image */
}

.PhotoGallery-scroll-container-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.PhotoGallery-scroll-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.PhotoGallery-scroll-left, .PhotoGallery-scroll-right {
  cursor: pointer;
  padding: 16px;
  font-size: 18px;
  user-select: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  outline: none;
  text-decoration: none;
}

.PhotoGallery-scroll-left:hover, .PhotoGallery-scroll-right:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.PhotoGallery-demo {
  cursor: pointer;
  width: 100px;
  margin-right: 10px;
  opacity: 0.5;
  object-fit: contain;
  padding: 5px 10px;
  height: 100%; /* Ensure images fill the column height */
  width: auto;
}

.PhotoGallery-active {
  border: 2px solid white;
  opacity: 0.9;
  box-shadow: 0px 1px 10px 1px #7d7d7d;
}

.PhotoGallery-Top-buttons{
  font-size: 18px;
  padding: 0px 16px 0px 16px;
  color: #303030;
  font-weight: 550;
  font-family: "Inter", sans-serif;
  background-color: #20336b;
  color: #e4e9f7;
  box-shadow: 1px 4px 5px 0px rgba(48, 48, 48, 0.45);
  border-radius: 24px;
  border: 1px solid #303030;
  height: 44px;
}

.PhotoGallery-Top-buttons {
  color: black;
  background-color: rgb(130, 194, 251);
  border: 1px solid black;
  border-radius: 16px;
  cursor: pointer;
  box-shadow: 1px 4px 5px 0px #20336b;
  font-weight: 700;
}

.PhotoGallery-zoom-buttons img{
  height: 40px;
  width: 40px;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}