.LoginSection {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    background: url('../../assets/Login_Back.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    animation: animateBg 5s linear infinite;
}

@keyframes animateBg {
    100% {
        filter: hue-rotate(360deg);
    }
}

.form-box {
    position: relative;
    width: 400px;
    height: 450px;
    background-color: transparent;
    border: 2px solid rgb(255, 255, 255, 0.5);
    border-radius: 20px;
    backdrop-filter: blur(24px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-box .form-value .heading {
    font-size: 2rem;
    color: #fff;
    text-align: center;
    animation-duration: 3s;
    animation-name: slidein;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.input-box {
    position: relative;
    margin: 30px 0;
    width: 310px;
    border-bottom: 2px solid #fff;
}

.input-box label {
    position: absolute;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    color: #fff;
    font-size: 1em;
    pointer-events: none;
    transition: .5s;
}

input:focus~label,
input:focus~:valid {
    top: -5px;
}

.input-box input {
    width: 100%;
    height: 50px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1em;
    padding: 0px 35px 0px 5px;
    color: #fff;
}

.input-box ion-icon {
    position: absolute;
    right: 8px;
    color: #fff;
    font-size: 1.2em;
    top: 20px;
}

.btntheam {
    width: 100%;
    height: 40px;
    border-radius: 40px;
    background-color: #fff;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    font-weight: 600;
}

.form-errors {
    margin-top: 10px;
    color: red;
}
.addbtn {
    display: flex;
    justify-content: flex-end;
}

.starcolor {
    color: red;
}

/* Media Query for Small Screens (up to 767px) */
@media (max-width: 767px) {
    .form-box {
        width: 80%; 
    }

    .input-box {
        width: 100%; 
        margin: 20px 0; 
    }

    .input-box input {
        font-size: 0.9em; 
    }

    .btntheam {
        font-size: 0.9em;
    }
}


@media (min-width: 768px) and (max-width: 1023px) {
    .form-box {
        width: 60%; 
    }

    .input-box {
        width: 100%;
        margin: 25px 0; 
    }

    .input-box input {
        font-size: 1em;
    }

    .btntheam {
        font-size: 1em; 
    }
}

/* Media Query for Large Screens (1024px and above) */
@media (min-width: 1024px) {
    .form-box {
        width: 400px; 
    }

    .input-box {
        width: 310px; 
        margin: 30px 0; 
    }

    .input-box input {
        font-size: 1em; 
    }

    .btntheam {
        font-size: 1em; 
    }
}

