/* export const imgURL = "https://asianlabapi.taptitechnology.com";
export const imgURL = "https://asianlabdimondtechapi.taptitechnology.com";
export const imgURL = "https://asianlabalgdapi.taptitechnology.com";
export const imgURL = "https://localhost:7021"; */

:root {
  --body-background: #e4e9f7;
  --body--color: #000;
  --link--color: navy;
  --card-header-color-rgb: 33, 37, 41;
  --card-background: #fff;
  --card-border: #f2f3f8;
  --card-color: #464457;
  --nav-background: #20336b;
  --btn-background-color: #20336b;
  --btn-background-color-hover: #00baf2;
  --btn-color: #fff;
  --btn-color-hover: #20336b;
}

[data-theme="dark"] {
  --body-background: #000;
  --text-primary: #fff;
  --card-background: #222327;
  --card-border: #ccc;
  --card-color: #fff;
  --card-header-color-rgb: #dadce0;
  --nav-background: #464446;
  --btn-background-color: #00baf2;
  --btn-background-color-hover: #20336b;
  --btn-color-hover: #fff;
}

* {
  transition: all 0.3s ease-in-out;
}

body {
  background-color: #eeeeee !important;
  font-family: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

.vr {
  width: 2px !important;
  min-height: 1.3em !important;
}

/* .ag-root{
  font-family:"Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important ;
}  */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  position: sticky !important;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 10px 10px -7px rgba(0, 0, 0, 0.51);
  margin-bottom: 20px !important;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgb(32 51 107);
}
.navbar-brand {
  margin-right: 0px !important;
}

.navbar-nav .nav-link,
.navFontSize {
  color: #ffffff !important;
  font-weight: 400;
  padding: 2px 5px !important;
  margin: 3px 3px !important;
  text-align: center;
}

@media (min-width: 550px) {
  .d-flex-550 {
    display: flex !important;
  }
}

.form-control-ofNavSearch {
  font-weight: 700;
  font-size: 16px !important;
  padding: 3px 10px;
}

@media (min-width: 768px) {
  .form-control-ofNavSearch {
    font-size: 16px;
  }

  .navbar-nav .nav-link,
  .navFontSize {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .form-control-ofNavSearch {
    font-size: 16px;
  }

  .navbar-nav .nav-link,
  .navFontSize {
    font-size: 18px !important;
    padding: 6px 15px;
    margin: 0px 8px;
  }
}

@media (min-width: 1200px) {
  .form-control-ofNavSearch {
    font-size: 14px;
  }

  .navbar-nav .nav-link,
  .navFontSize {
    font-size: 16px !important;
  }
}

.card-footer {
  padding: 16px 16px 16px 16px;
  text-align: center;
  background-color: var(--card-background);
  border-top: solid 2px var(--card-border);
  font-size: 16px;
  color: #464457;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin: 0px 10px 0px 10px;
}

.card-header > span {
  background-color: #ffffff !important;
  font-size: 20px;
  font-weight: 700;
}

label {
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
}

.topmargine10 {
  margin-top: 10px;
}

.btn-primary {
  color: var(--btn-color);
  background-color: var(--btn-background-color);
  border-color: var(--btn-background-color);
  transition: 0.2s ease-in-out;
}

.btn-primary:hover {
  color: var(--btn-color-hover);
  background-color: var(--btn-background-color-hover);
  border-color: var(--btn-background-color-hover);
  font-weight: bold;
  cursor: pointer;
}

.card {
  padding: 8px;
  border: 0 solid #d9dee3;
  border-radius: 0.5rem;
  background-clip: padding-box;
  border: 1px solid #ffffff !important;
  box-shadow: 0 2px 6px 0 rgba(67, 89, 113, 0.12) !important;
  margin: 10px;
}

.card-header {
  background-color: #ffffff !important;
}

.bg-dark {
  background-color: #20336b !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
}

/* .navbar-nav .nav-link,
.navFontSize {
  color: #ffffff !important;
  font-weight: 400;
  padding: 2px 5px !important;
  margin: 3px 3px !important;
  text-align: center;
} */

.navbar-nav .nav-link .fa,
.navbar-nav .nav-link .fas {
  display: none;
}

.nav-underline .nav-link {
  border: 2px solid #20336b;
  color: #20336b;
  font-weight: 700;
  margin: 6px;
  padding: 8px;
}

.form-control-ofNavSearch {
  font-weight: 700;
  font-size: 10px;
  padding: 3px 10px;
}

.nav-underline .nav-link:hover {
  border: 2px solid #0d6efd;
  background-color: #0d6efd;
  color: white;
  font-weight: 700;
  margin: 6px;
  padding: 8px;
}

.nav-underline .nav-link.active {
  background-color: #20336b;
  border: 2px solid #20336b;
  color: #fff;
  font-weight: 700;
  margin: 6px;
  padding: 8px;
}

.pad3 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.mtb5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.pl10r0 {
  padding-left: 10px;
  padding-right: 0px;
}

.pr20 {
  padding-right: 20px;
}

.txtlbl {
  margin-top: 5px;
  font-size: 15px;
}

.pagenotfount {
  margin: 20px 0px 0px 0px;
  color: #000;
  display: flex;
  justify-content: center;
}

.iconsFonts {
  font-size: 25px !important;
  vertical-align: middle !important;
  cursor: pointer;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #4eefff;
}

.navbar-brand:hover {
  color: #4eefff;
}

.deleteIcon:hover {
  color: red;
}

.editIcon:hover {
  color: green;
}

.attachIcon:hover {
  color: blue;
}

.mb-20px {
  margin-bottom: -20px;
}

.checkbox_HW {
  height: 24px;
  width: 24px;
}

.barcode-container {
  height: 100px;
  width: 200px;
  border: 1px solid #000;
  padding: 4px;
}

.txtCenter {
  text-align: center;
}

.blog-thumb {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 3px 4px 15px #00000026;
}

.imgFixCover {
  height: 200px;
  cursor: pointer;
  object-fit: contain;
  margin-right: 12px;
}

.divLabel {
  background-color: #d2d6de;
  display: inline-block;
  padding: 5px 12px;
  font-size: 16px;
  margin-top: 2px;
  margin-bottom: 2px;
  text-align: center;
  color: #333;
}

.btn-primary:disabled {
  background: #20336b;
  color: #fff;
  cursor: not-allowed;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.blog-thumb {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 3px 4px 15px #00000026;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 580px) {
  label {
    display: flex;
    justify-content: left;
    margin-bottom: 12px;
  }

  .iconsFonts {
    font-size: 18px !important;
  }

  .dashboardSummaryDiv {
    margin-top: 10px;
  }
}

.center-text .ag-header-cell-label {
  display: flex;
  justify-content: center;
}

.flex-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ag-responsive-grid {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;
}

@media (max-width: 768px) {
  .ag-responsive-grid {
    width: 100% !important;
  }

  .searchBtn {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  .noWidth {
    display: inline-block;
    width: 150px !important;
  }

  .machineTitle {
    font-size: 14px;
  }
}

body.modal-open {
  overflow: hidden;
}

.modal-open {
  overflow: hidden !important;
}

.modal {
  overflow-y: auto !important;
}

.agGridSumDiv {
  height: 50px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.custom-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;
}

.custom-footer div {
  margin-bottom: 5px;
}

/* HTML: <div class="loader"></div> */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000a8;
  z-index: 1000;
}

.overlay-content {
  position: fixed; /* Fixed ensures it stays in view during scrolling */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #007bff;
  z-index: 1001;
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 4px solid;
  border-color: #2ac5f4 #2ac5f4 transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader:before,
.loader:after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid;
  border-color: transparent transparent #20336b #20336b;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

.loader:after {
  width: 70px;
  height: 70px;
  border-color: #2ac5f4 #2ac5f4 transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

.reportDiv {
  margin-bottom: 10px;
  background-color: #263369;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  padding: 4px;
  font-weight: 600;
}

.mixStoneReport table th {
  background-color: #20336b;
  color: #fff;
  font-weight: 600;
}

.table-verified table th {
  background-color: silver;
  color: #263369;
  font-weight: 600;
}

.mixTableHeader {
  background-color: #263369;
  color: white;
  justify-content: space-between;
  padding: 10px;
}

.mixTableHeaderVerify {
  background-color: silver;
  color: #263369;
  justify-content: space-between;
  padding: 10px;
}

@keyframes lqt {
  0%,
  100% {
    background-image: linear-gradient(#3dbdef 40px, transparent 0);
    background-position: 0% 0px;
  }

  50% {
    background-image: linear-gradient(#3dbdef 40px, transparent 0);
    background-position: 0% 40px;
  }

  50.1% {
    background-image: linear-gradient(#3dbdef 40px, transparent 0);
    background-position: 0% -40px;
  }
}

@keyframes lqb {
  0% {
    background-image: linear-gradient(#3dbdef 40px, transparent 0);
    background-position: 0 40px;
  }

  100% {
    background-image: linear-gradient(#3dbdef 40px, transparent 0);
    background-position: 0 -40px;
  }
}

@keyframes spinx {
  0%,
  49% {
    transform: rotate(0deg);
    background-position: 50% 36px;
  }

  51%,
  98% {
    transform: rotate(180deg);
    background-position: 50% 4px;
  }

  100% {
    transform: rotate(360deg);
    background-position: 50% 36px;
  }
}

.mixtableheader {
  background-color: #263369;
  color: white;
  justify-content: space-between;
  padding: 10px;
}

.remarkslbl {
  background-color: #ffff85;
  padding: 2px 8px;
  border-radius: 8px;
  text-align: center;
}

.summaryTable table th {
  background-color: #20336b;
  color: #fff;
  font-weight: 600;
}

.custom-button {
  width: 101px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  height: 39px;
  margin-left: 12px;
}

.custom-modal .modal-dialog {
  width: 800px;
}

.navbar-brand:focus,
.navbar-brand:hover {
  color: rgb(255 239 239);
}

/* select */

.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.css-13cymwt-control {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.selectEndIcon {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}

.modal-header .btn-close {
  color: #000;
}

.modal-header .btn-close-white {
  filter: none;
}

.machineDeshbord-Image-Popup {
  align-items: center;
  justify-content: center;
  padding: 0px;
}
.machineDeshbord-Image-Popup img {
  height: 15vh;
  width: 100%;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  padding: 4px;
  border-radius: 8px;
  text-decoration: none;
  color: black;
  font-size: 20px;
}

.machineDeshbord-image-container {
  display: flex;
  flex-direction: column;
  border: 2px solid rgb(182, 182, 182);
  margin: 2px;
  border-radius: 8px;
  /* Stack items vertically */
}

.machineDeshbord-delete-button {
  align-self: flex-end; /* Align the button to the right side of the container */
}

.lightgalleryimage {
  display: inline-block;
  font-weight: bold;
  text-align: center;
  background-color: #d3d6de;
  padding: 8px;
  margin: 8px;
  border-radius: 8px;
  text-decoration: none;
  color: black;
  font-size: 20px;
  width: 200px;
  height: auto;
}

/* .modal-header .btn-close {
  background-image: url("./assets/icons8-close-94.png");
  background-size: 20px;
    background-repeat: no-repeat;
    opacity: 1;
} */

.modal-header .btn-close {
  opacity: 1;
  background-image: url("./assets/image.png");
  background-size: 20px;
  background-repeat: no-repeat;
  display: flex;
  position: absolute;
  top: -0.5em;
  right: -0.5em;
  background-color: #20336b;
  border-radius: 25px;
  box-shadow: 0px 3px 15px 2px rgba(48, 48, 48, 0.45);
  padding: 10px;
  border: 1px solid white;
}
.modal-header .btn-close:hover {
  background-color: red;
  transition: background-color 0.3s ease-in-out;
}

.modal-fullscreen .modal-content .modal-header .btn-close {
  opacity: 1;
  background-image: url("./assets/image.png") !important;
  background-size: 20px !important;
  background-repeat: no-repeat;
  display: flex;
  position: relative !important;
  top: 0 !important;
  right: 0 !important;
}

.modal-title {
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  margin: 0;
}
